import { extractProperty } from '@/lib/notion'
import { Row, Stack } from '@christiankaindl/lyts'
import { NotionImage } from '../Notion/Block'
import { extractPropertyText } from '../Notion/PropertyText'
import Text from '../Text/Text'

export function Author ({ author }) {
  return (
    <Row gap={0.5} key={author.id}>
      <NotionImage
        blockId={author?.id}
        width={36}
        height={36}
        style={{
          borderRadius: extractProperty(author.properties.Organization, 'checkbox') ? 7 : 99,
        }}
        alt=''
      />
      <Stack gap={0}>
        <Text size='0.9em' weight={500}>{author.properties.Name.title?.[0].plain_text}</Text>
        {extractPropertyText(author.properties.Tagline) && (
          <Text size='small' color='tertiary' style={{ marginTop: -3 }}>{extractPropertyText(author.properties.Tagline)}</Text>
        )}
      </Stack>
    </Row>
  )
}
