import { DynamoDB } from '@aws-sdk/client-dynamodb'
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb'
import { S3, S3Client } from '@aws-sdk/client-s3'
import { env } from '@/env'

export const s3 = new S3({
  credentials: {
    accessKeyId: env.AWS_ACCESS_KEY_ID_GF,
    secretAccessKey: env.AWS_SECRET_ACCESS_KEY_GF,
  },
  region: env.AWS_REGION_GF,
})

export const s3Client = new S3Client({
  credentials: {
    accessKeyId: env.AWS_ACCESS_KEY_ID_GF,
    secretAccessKey: env.AWS_SECRET_ACCESS_KEY_GF,
  },
  region: env.AWS_REGION_GF,
})

const ddbClient = new DynamoDB({
  credentials: {
    accessKeyId: env.AWS_ACCESS_KEY_ID_GF,
    secretAccessKey: env.AWS_SECRET_ACCESS_KEY_GF,
  },
  region: env.AWS_REGION_GF,
})
export const ddb = DynamoDBDocument.from(ddbClient)
